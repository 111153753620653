<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 16:21:40
 * @ Description: Component displaying the public individual profile details tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            md="6"
        >
            <!-- Details -->
            <profile-public-individual-details-component />
            <!-- Details -->

            <!-- Location -->
            <profile-public-details-location-component
                :avatar="avatar"
            />
            <!-- Location -->

            <!-- Social Media -->
            <profile-public-details-social-media-component />
            <!-- Social Media -->
        </v-col>
    </v-row>
</template>
<script>
    import ProfilePublicIndividualDetailsComponent from '@/components/profile/public/individual/details/ProfilePublicIndividualDetailsComponent.vue'
    import ProfilePublicDetailsLocationComponent from '@/components/profile/public/general/details/ProfilePublicDetailsLocationComponent.vue'
    import ProfilePublicDetailsSocialMediaComponent from '@/components/profile/public/general/details/ProfilePublicDetailsSocialMediaComponent.vue'

    export default {
        name: 'ProfilePublicIndividualDetailsTabComponent.vue',

        components: {
            ProfilePublicIndividualDetailsComponent,
            ProfilePublicDetailsLocationComponent,
            ProfilePublicDetailsSocialMediaComponent
        },

        props: {
            avatar: {
                type: String
            }
        }
    }
</script>
