<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-29 15:20:39
 * @ Description: Component displaying the public individual profile details section in the details tab.
 -->

<template>
    <!-- Desktop -->
    <v-row
        align="center"
        v-if="$vuetify.breakpoint.mdAndUp"
    >
        <!-- Heading -->
        <v-col
            class="flex-shrink-1 flex-grow-0 text-center os-13-r"
        >
            <v-avatar
                size="100"
                class="mb-2"
            >
                <v-img
                    :src="individual.avatar ? individual.avatar : '/general/FixerIndividualIcon.png'"
                    contain
                />
            </v-avatar>
        </v-col>
        <v-col
            class="flex-grow-1 flex-shrink-0"
        >
            <div
                class="os-22-sb"
            >
                {{ individual.first_name }} {{ individual.last_name }}
            </div>
        </v-col>
        <!-- Heading -->

        <!-- Personal Details -->
        <v-col
            cols="12"
            class="os-22-sb"
        >
            Personal Details
        </v-col>
        <v-col
            cols="12"
            class="pa-0"
        >
            <v-divider />
        </v-col>
        <!-- <v-col
            cols="6"
            class="pb-0 os-15-sb"
        >
            First Name
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                {{ individual.first_name }}
            </div>
        </v-col>
        <v-col
            cols="6"
            class="pb-0 os-15-sb"
        >
            Last Name
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                {{ individual.last_name }}
            </div>
        </v-col> -->
        <v-col
            cols="6"
            class="pb-0 os-15-sb"
        >
            Email
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                {{ individual.email }}
            </div>
        </v-col>
        <v-col
            cols="6"
            class="pb-0 os-15-sb"
        >
            Contact Number
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                <span v-if="individual.contact_number_1">{{ individual.contact_number_1 }}</span>
                <span v-else style="color: #8F9BB3;">No contact number added</span>
            </div>
        </v-col>
        <!-- Personal Details -->
    </v-row>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-row
        align="center"
        v-else-if="$vuetify.breakpoint.smAndDown"
    >
        <!-- Heading -->
        <v-col
            :class="$vuetify.breakpoint.sm ? 'flex-shrink-1 flex-grow-0' : ''"
            class="text-center"
            :cols="$vuetify.breakpoint.sm ? '' : '12'"
        >
            <v-avatar
                size="100"
                class="mb-2"
            >
                <v-img
                    :src="individual.avatar ? individual.avatar : '/general/FixerIndividualIcon.png'"
                    contain
                />
            </v-avatar>
        </v-col>
        <v-col
            :class="$vuetify.breakpoint.sm ? 'flex-grow-1 flex-shrink-0' : 'text-center'"
            :cols="$vuetify.breakpoint.sm ? '' : '12'"
        >
            <div
                class="os-22-sb"
            >
                {{ individual.first_name }} {{ individual.last_name }}
            </div>
        </v-col>
        <!-- Heading -->

        <!-- Personal Details -->
        <v-col
            cols="12"
            class="os-22-sb"
        >
            Personal Details
        </v-col>
        <v-col
            cols="12"
            class="pa-0"
        >
            <v-divider />
        </v-col>
        <!-- <v-col
            cols="12"
            sm="6"
            class="pb-0 os-15-sb"
        >
            First Name
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                {{ individual.first_name }}
            </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            class="os-15-sb"
            :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
        >
            Last Name
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                {{ individual.last_name }}
            </div>
        </v-col> -->
        <v-col
            cols="12"
            sm="6"
            class="pb-0 os-15-sb"
        >
            Email
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                {{ individual.email }}
            </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            class="os-15-sb"
            :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
        >
            Contact Number
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                <span v-if="individual.contact_number_1">{{ individual.contact_number_1 }}</span>
                <span v-else style="color: #8F9BB3;">No contact number added</span>
            </div>
        </v-col>
        <!-- Personal Details -->
    </v-row>
    <!-- Mobile -->
</template>
<script>
    import { IndividualProfileDetailController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfilePublicIndividualDetailsComponent',

        data () {
            return {
                individualProfileDetailController: null,
                individual: {
                    id: null,
                    first_name: '',
                    last_name: '',
                    email: '',
                    contact_number_1: '',
                    avatar: null
                }
            }
        },

        beforeMount () {
            this.individualProfileDetailController = new IndividualProfileDetailController()
        },

        async mounted () {
            await this.getIndividualProfileDetail()
        },

        methods: {
            async getIndividualProfileDetail () {
                try {
                    this.individual = await this.individualProfileDetailController.retrieve(this.$route.params.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
