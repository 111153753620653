<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 16:04:43
 * @ Description: Component displaying the public general profile social media section in the details tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            class="os-22-sb"
        >
            Social Media
        </v-col>
        <v-col
            cols="12"
            class="py-0"
        >
            <v-divider />
        </v-col>

        <!-- Facebook -->
        <v-col
            cols="12"
            class="pb-0 os-15-sb"
        >
            <v-icon
                color="#006FD6"
                class="mr-1"
            >
                {{ mdiFacebook }}
            </v-icon>
            Facebook
            <br />
            <div
                class="pt-1 pb-3 os-13-r text-truncate"
                style="color: #2E567A;"
            >
                <span @click="openSocialMediaLink(social.facebook)" style="text-decoration: underline; cursor: pointer;" v-if="social.facebook">{{ social.facebook }}</span>
                <span v-else style="color: #8F9BB3;">No Facebook link added</span>
            </div>
        </v-col>
        <!-- Facebook -->

        <!-- Twitter -->
        <v-col
            cols="12"
            class="py-0 os-15-sb"
        >
            <v-icon
                color="#0095FF"
                class="mr-1"
            >
                {{ mdiTwitter }}
            </v-icon>
            Twitter
            <br />
            <div
                class="pt-1 pb-3 os-13-r text-truncate"
                style="color: #2E567A;"
            >
                <span @click="openSocialMediaLink(social.twitter)" style="text-decoration: underline; cursor: pointer;" v-if="social.twitter">{{ social.twitter }}</span>
                <span v-else style="color: #8F9BB3;">No Twitter link added</span>
            </div>
        </v-col>
        <!-- Twitter -->

        <!-- LinkedIn -->
        <v-col
            cols="12"
            class="py-0 os-15-sb"
        >
            <v-icon
                color="#0A579F"
                class="mr-1"
            >
                {{ mdiLinkedin }}
            </v-icon>
            LinkedIn
            <br />
            <div
                class="pt-1 pb-3 os-13-r text-truncate"
                style="color: #2E567A;"
            >
                <span @click="openSocialMediaLink(social.linkedin)" style="text-decoration: underline; cursor: pointer;" v-if="social.linkedin">{{ social.linkedin }}</span>
                <span v-else style="color: #8F9BB3;">No LinkedIn link added</span>
            </div>
        </v-col>
        <!-- LinkedIn -->

        <!-- Webiste -->
        <v-col
            cols="12"
            class="py-0 os-15-sb"
        >
            <v-icon
                color="#EA7101"
                class="mr-1"
            >
                {{ mdiWeb }}
            </v-icon>
            Website
            <br />
            <div
                class="pt-1 pb-3 os-13-r text-truncate"
                style="color: #2E567A;"
            >
                <span @click="openSocialMediaLink(social.website)" style="text-decoration: underline; cursor: pointer;" v-if="social.website">{{ social.website }}</span>
                <span v-else style="color: #8F9BB3;">No website link added</span>
            </div>
        </v-col>
        <!-- Webiste -->

        <!-- Instagram -->
        <v-col
            cols="12"
            class="pt-0 os-15-sb"
        >
            <v-icon
                color="#E80620"
                class="mr-1"
            >
                {{ mdiInstagram }}
            </v-icon>
            Instagram
            <br />
            <div
                class="pt-1 pb-3 os-13-r text-truncate"
                style="color: #2E567A;"
            >
                <span @click="openSocialMediaLink(social.instagram)" style="text-decoration: underline; cursor: pointer;" v-if="social.instagram">{{ social.instagram }}</span>
                <span v-else style="color: #8F9BB3;">No Instagram link added</span>
            </div>
        </v-col>
        <!-- Webiste -->
    </v-row>
</template>
<script>
    import { mdiFacebook, mdiTwitter, mdiLinkedin, mdiWeb, mdiInstagram } from '@mdi/js'
    import { CompanyProfileSocialController, IndividualProfileSocialController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfilePublicDetailsSocialMediaComponent',

        data () {
            return {
                mdiFacebook,
                mdiTwitter,
                mdiLinkedin,
                mdiWeb,
                mdiInstagram,
                social: {
                    facebook: null,
                    twitter: null,
                    linkedin: null,
                    website: null,
                    instagram: null,
                },
                companyProfileSocialController: null,
                individualProfileSocialController: null
            }
        },

        beforeMount () {
            this.companyProfileSocialController = new CompanyProfileSocialController()
            this.individualProfileSocialController = new IndividualProfileSocialController()
        },

        async mounted () {
            await this.getProfileSocial()
        },

        methods: {
            async getProfileSocial () {
                try {
                    if (this.$route.path.includes('company')) this.social = await this.companyProfileSocialController.retrieve(this.$route.params.id)
                    else if (this.$route.path.includes('individual')) this.social = await this.individualProfileSocialController.retrieve(this.$route.params.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            openSocialMediaLink (link) {
                if(link.indexOf('//') < 0 ) {
                    link = 'http://' + link
                }

                window.open(link, '_blank')
            }
        }
    }
</script>
