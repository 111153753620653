<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 16:41:26
 * @ Description: Component displaying the public general public location section in the details tab.
 -->

<template>
    <!-- Desktop -->
    <v-row
        v-if="$vuetify.breakpoint.mdAndUp"
    >
        <v-col
            cols="12"
            class="os-22-sb"
        >
            Location
        </v-col>
        <v-col
            cols="12"
            class="py-0"
        >
            <v-divider />
        </v-col>

        <!-- Google Autocomplete -->
        <v-col
            cols="12"
            class="pb-0 os-15-sb"
            v-if=" $route.path.includes('company')"
        >
            Address
            <br />
            <div
                class="pt-1 pb-3 os-13-r text-truncate"
                style="color: #2E567A;"
            >
                <span v-if="address.address_line_1">{{ address.address_line_1 }}</span>
                <span v-else style="color: #8F9BB3;">No address added</span>
            </div>
        </v-col>
        <!-- Google Autocomplete -->

        <v-col
            cols="6"
            class="os-15-sb"
            :class="address.google_places_id && $route.path.includes('individual') ? 'pb-0' : address.google_places_id ? 'py-0' : 'pb-3'"
        >
            Country
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                <span v-if="address.country.name">{{ address.country.name }}</span>
                <span v-else style="color: #8F9BB3;">No country added</span>
            </div>
        </v-col>
        <v-col
            cols="6"
            class="os-15-sb"
            :class="address.google_places_id && $route.path.includes('individual') ? 'pb-0' : address.google_places_id ? 'py-0' : 'pb-3'"
        >
            City
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                <span v-if="address.city">{{ address.city }}</span>
                <span v-else style="color: #8F9BB3;">No city added</span>
            </div>
        </v-col>

        <!-- Map -->
        <v-col
            cols="12"
            class="pt-0"
            v-if="address.google_places_id && $route.path.includes('company')"
        >
            <general-map-component
                :mapMarkers="mapMarkers"
                :avatar="avatar"
            />
        </v-col>
        <!-- Map -->
    </v-row>
    <!-- Desktop -->

    <!-- Mobile -->
    <v-row
        v-else-if="$vuetify.breakpoint.smAndDown"
    >
        <v-col
            cols="12"
            order="1"
            class="os-22-sb"
        >
            Location
        </v-col>
        <v-col
            cols="12"
            order="2"
            class="py-0"
        >
            <v-divider />
        </v-col>

        <!-- Google Autocomplete -->
        <v-col
            cols="12"
            sm="6"
            order="3"
            class="pb-0 os-15-sb"
            v-if="$route.path.includes('company')"
        >
            Address
            <br />
            <div
                class="pt-1 pb-3 os-13-r text-truncate"
                style="color: #2E567A;"
            >
                <span v-if="address.address_line_1">{{ address.address_line_1 }}</span>
                <span v-else style="color: #8F9BB3;">No address added</span>
            </div>
        </v-col>
        <!-- Google Autocomplete -->

        <v-col
            cols="12"
            sm="6"
            order="4"
            class="os-15-sb"
            :class="$vuetify.breakpoint.sm || $route.path.includes('individual') ? 'pb-0' : 'py-0'"
        >
            Country
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                <span v-if="address.country.name">{{ address.country.name }}</span>
                <span v-else style="color: #8F9BB3;">No country added</span>
            </div>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            order="6"
            class="os-15-sb"
            :class="$vuetify.breakpoint.sm || $route.path.includes('individual') ? 'pb-0' : 'py-0'"
        >
            City
            <br />
            <div
                class="pt-1 pb-3 os-13-r"
                style="color: #2E567A;"
            >
                <span v-if="address.city">{{ address.city }}</span>
                <span v-else style="color: #8F9BB3;">No city added</span>
            </div>
        </v-col>

        <!-- Map -->
        <v-col
            cols="12"
            class="pt-0"
            order="8"
            order-sm="9"
            v-if="address.google_places_id && $route.path.includes('company')"
        >
            <general-map-component
                :mapMarkers="mapMarkers"
                :avatar="avatar"
            />
        </v-col>
        <!-- Map -->
    </v-row>
    <!-- Mobile -->
</template>
<script>
    import GeneralMapComponent from '@/components/general/map/GeneralMapComponent'
    import { GeneralProfileAddressController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfilePublicDetailsLocationComponent',

        components: {
            GeneralMapComponent
        },

        props: {
            companyUserID: {
                type: Number
            },

            avatar: {
                type: String
            }
        },

        watch: {
            companyUserID: {
                async handler (value) {
                    if (value) await this.getProfileLocation(value)
                },
                deep: true
            }
        },

        data () {
            return {
                mapMarkers: [],
                address: {
                    user_id: null,
                    name: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                generalProfileAddressController: null
            }
        },

        beforeMount () {
            this.generalProfileAddressController = new GeneralProfileAddressController()
        },

        async mounted () {
            if (this.$route.path.includes('individual')) await this.getProfileLocation(this.$route.params.id)
            else if (this.$route.path.includes('company') && this.companyUserID) this.getProfileLocation(this.companyUserID)
        },

        methods: {
            async getProfileLocation (id) {
                try {
                    this.address = await this.generalProfileAddressController.retrieve(id)

                    if (this.address.lat && this.address.lng) {
                        this.$nextTick(() => {
                            this.mapMarkers = [
                                {
                                    lat: Number(this.address.lat),
                                    lng: Number(this.address.lng)
                                }
                            ]
                        })
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
